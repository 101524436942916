import React from "react"
// import { Link } from "gatsby"
import { graphql, useStaticQuery } from "gatsby"
import {
  ReactCompareSlider,
  ReactCompareSliderImage,
} from "react-compare-slider"

const ComparisonSlider = ({ location }) => {
  const { after, before, afterTwo, beforeTwo, afterThree, beforeThree } =
    useStaticQuery(graphql`
      {
        after: file(relativePath: { eq: "comparison/after.jpeg" }) {
          publicURL
        }
        before: file(relativePath: { eq: "comparison/before.jpeg" }) {
          publicURL
        }
        afterTwo: file(relativePath: { eq: "comparison/after-two.jpeg" }) {
          publicURL
        }
        beforeTwo: file(relativePath: { eq: "comparison/before-two.jpeg" }) {
          publicURL
        }
        afterThree: file(relativePath: { eq: "comparison/after-three.jpeg" }) {
          publicURL
        }
        beforeThree: file(
          relativePath: { eq: "comparison/before-three.jpeg" }
        ) {
          publicURL
        }
      }
    `)

  return (
    <div className="grid grid-cols-1 gap-6 sm:grid-cols-3">
      <div>
        <ReactCompareSlider
          onlyHandleDraggable={true}
          itemOne={
            <ReactCompareSliderImage
              src={before.publicURL}
              srcSet={before.publicURL}
              alt="Before edited image of two girls on the street"
            />
          }
          itemTwo={
            <ReactCompareSliderImage
              src={after.publicURL}
              srcSet={after.publicURL}
              alt="After edited image of two girls on the street"
            />
          }
        />
        {/* <div className="text-center py-3 font-medium">
          <Link to="/presets/italiana-bundle">Italiana Bundle</Link>
        </div> */}
      </div>
      <div>
        <ReactCompareSlider
          onlyHandleDraggable={true}
          itemOne={
            <ReactCompareSliderImage
              src={beforeTwo.publicURL}
              srcSet={beforeTwo.publicURL}
              alt="Before edited image of a girl"
            />
          }
          itemTwo={
            <ReactCompareSliderImage
              src={afterTwo.publicURL}
              srcSet={afterTwo.publicURL}
              alt="After edited image of a girl"
            />
          }
        />
        {/* <div className="text-center py-3 font-medium">
          <Link to="/presets/cinematica-mobile">Cinematica Mobile</Link>
        </div> */}
      </div>
      <div>
        <ReactCompareSlider
          onlyHandleDraggable={true}
          itemOne={
            <ReactCompareSliderImage
              src={beforeThree.publicURL}
              srcSet={beforeThree.publicURL}
              alt="Before edited image of a girl"
            />
          }
          itemTwo={
            <ReactCompareSliderImage
              src={afterThree.publicURL}
              srcSet={afterThree.publicURL}
              alt="After edited image of a girl"
            />
          }
        />
        {/* <div className="text-center py-3 font-medium">
          <Link to="/presets/full-season-bundle">Full Season Bundle</Link>
        </div> */}
      </div>
    </div>
  )
}

export default ComparisonSlider
